<template>
  <div>
    <v-list-item v-for="(benefit, index) in benefits" :key="index">
      <v-list-item-title>
        <v-icon
          :color="iconColor ? iconColor : 'grey'"
          size="26"
          class="mr-2 text--darken-1 font-weight-bold"
        >
          done
        </v-icon>
        {{ benefit }}
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "BenefitsList",
  props: {
    benefits: {
      type: Array,
      default: () => [],
    },
    iconColor: {
      type: [String, Boolean],
      default: "grey",
    },
  },
};
</script>

<style scoped></style>
